<template>
  <!-- 실제 본문 시작 -->
  <div class="cont-body-wrap">
    <div class="top-head-box st2">
      <div class="com-inner-box-st1" v-if="!isHistoryPage">
        <div class="btn-box"><a href="javascript:;" class="btn-back" @click="$emit('changeSt','register')">이전페이지</a></div>
      </div>
    </div>
    <div class="inner-body-wrap st-full">
      <div class="layout-btn-fixed st-none">
        <div class="com-inner-box-st1">
          <div class="gift-lst-box">
            <div class="tit-box">신청내역</div>
            <div class="row-box" v-for="(data, i) in history.content" :key="i">
              <div class="date-box">
                <div class="txt1">{{ data.present.title }}</div>
                <div class="txt2">{{ formatDate(data.orderedAt) }}</div>
              </div>
              <div class="address-box">
                <div class="txt1">신청선물</div>
                <div class="txt3">{{ data.orderProductName }}</div>
              </div>
              <div class="address-box">
                <div class="txt1">배송지 정보</div>
                <div class="txt2"><span>{{ data.name }}</span> <em>{{ data.mobileNo }}</em></div>
                <div class="txt3">({{ data.zipCode }}) {{ data.address + " " + data.addressDetail }}</div>
              </div>
            </div>

          </div>

          <!-- class : pagination (S) -->
          <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
          <!-- class : pagination (E) -->
        </div>

      </div>

    </div>
  </div>
  <!-- 실제 본문 종료 -->

  <!-- id : contents : (E) -->
</template>

<script>
import PresentApi from "@/api/PresentApi";
import DgPagination from "@/views/component/DgPagination";
import moment from "moment";

export default {
  name: "History",
  components:{
    DgPagination
  },
  props:{
    memberInfo : {},
    isHistoryPage:{}
  },
  watch:{
  },
  data(){
    return {
      history:{},

      pagination:{
        currentPage: 1,
        pageSize: 5,
        pageGroupSize: 5,
        totalPages:0,
        totalElements:0
      },
    }
  },
  computed:{
  },
  created(){
    this.onClickSearch();
  },
  methods:{
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    onClickSearch(){
      let param = {
        authNo : this.memberInfo.authNo,
        mobileNo : this.memberInfo.mobileNo,
        searchState : "STATE_ORDERED",
        size: this.pagination.pageSize,
        page: this.pagination.currentPage
      };
      PresentApi.orderList(this.memberInfo.userId, param).then((data)=>{
        //this.history = data.filter(d => d.status=="");
        this.history=data;

        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
      });
    },
    formatDate(value) {
      return value!=null? moment(value).format('YYYY-MM-DD HH:mm') : '';
    }
  },
}
 
</script>

<style scoped>

</style>